<template>
  <div class="bg-background-primary">
    <div v-if="userStore.isLoading" class="flex h-screen w-screen items-center justify-center">
      <LoadingSpinner color="primary" />
    </div>

    <NetworkError v-else-if="userStore.hasNetworkError" class="h-screen w-screen" />

    <div v-else>
      <router-view />
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted } from "vue";

import LoadingSpinner from "./components/LoadingSpinner.vue";
import NetworkError from "./components/NetworkError.vue";
import { useTheme } from "./composables/useTheme";
import { useUserStore } from "./store/user";

const userStore = useUserStore();

const { loadTheme } = useTheme();

onMounted(loadTheme);
</script>
