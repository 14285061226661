import { DepartmentsEnum } from "./enums";

export const documents = {
  termsOfUse: "https://static.espresso-cloud.com/exemplos/exemplo_termos-de-uso.pdf",
  privacyPolicy: "https://static.espresso-cloud.com/exemplos/exemplo_politica-de-privacidade.pdf",
};

export const socialInfo = {
  phone: "(11) 91234-5678",
  facebook: "https://www.facebook.com/",
  linkedin: "https://www.linkedin.com/",
  instagram: "https://instagram.com/",
  email: "devops@launchpad.com.br",
};

export const BrazilianStates = [
  { value: "AC", name: "Acre" },
  { value: "AL", name: "Alagoas" },
  { value: "AM", name: "Amazonas" },
  { value: "AP", name: "Amapá" },
  { value: "BA", name: "Bahia" },
  { value: "CE", name: "Ceará" },
  { value: "DF", name: "Distrito Federal" },
  { value: "ES", name: "Espírito Santo" },
  { value: "GO", name: "Goiás" },
  { value: "MA", name: "Maranhão" },
  { value: "MG", name: "Minas Gerais" },
  { value: "MS", name: "Mato Grosso do Sul" },
  { value: "MT", name: "Mato Grosso" },
  { value: "PA", name: "Pará" },
  { value: "PB", name: "Paraíba" },
  { value: "PE", name: "Pernambuco" },
  { value: "PI", name: "Piauí" },
  { value: "PR", name: "Paraná" },
  { value: "RJ", name: "Rio de Janeiro" },
  { value: "RN", name: "Rio Grande do Norte" },
  { value: "RO", name: "Rondônia" },
  { value: "RR", name: "Roraima" },
  { value: "RS", name: "Rio Grande do Sul" },
  { value: "SC", name: "Santa Catarina" },
  { value: "SE", name: "Sergipe" },
  { value: "SP", name: "São Paulo" },
  { value: "TO", name: "Tocantins" },
];

export const DepartmentsTranslation = {
  [DepartmentsEnum.MARKETING]: "Marketing",
  [DepartmentsEnum.SALES]: "Vendas",
  [DepartmentsEnum.DESIGN]: "Design",
  [DepartmentsEnum.DEVELOPMENT]: "Tecnologia da Informação (TI)",
  [DepartmentsEnum.FINANCES]: "Finanças",
};

export const DepartmentsOptions = [
  { value: DepartmentsEnum.MARKETING, label: DepartmentsTranslation[DepartmentsEnum.MARKETING] },
  { value: DepartmentsEnum.SALES, label: DepartmentsTranslation[DepartmentsEnum.SALES] },
  { value: DepartmentsEnum.DESIGN, label: DepartmentsTranslation[DepartmentsEnum.DESIGN] },
  {
    value: DepartmentsEnum.DEVELOPMENT,
    label: DepartmentsTranslation[DepartmentsEnum.DEVELOPMENT],
  },
  { value: DepartmentsEnum.FINANCES, label: DepartmentsTranslation[DepartmentsEnum.FINANCES] },
];
