<template>
  <div class="flex flex-col items-center justify-center">
    <div class="loading relative" :style="`width: ${size}px; height: ${size}px;`">
      <div :style="style"></div>
      <div :style="style"></div>
      <div :style="style"></div>
      <div :style="style"></div>
    </div>
    <p v-if="showText" class="mt-5 font-semibold">Carregando...</p>
  </div>
</template>

<script setup lang="ts">
export type ColorsName = "primary" | "white" | "error";

import { computed } from "vue";

const props = withDefaults(
  defineProps<{ color?: ColorsName; size?: number; stroke?: number; showText?: boolean }>(),
  {
    color: "white",
    size: 80,
    stroke: 10,
    showText: false,
  }
);

const colors: Record<ColorsName, string> = {
  primary: "var(--primary)",
  white: "var(--white)",
  error: "var(--error)",
};

const style = computed(
  () => `border-width: ${props.stroke}px; border-top-color: ${colors[props.color]}`
);
</script>

<style lang="postcss" scoped>
.loading div {
  position: absolute;
  width: 100%;
  height: 100%;

  border-radius: 50%;
  border-color: transparent;

  animation: rotate 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}

.loading div:nth-child(1) {
  animation-delay: -0.45s;
}

.loading div:nth-child(2) {
  animation-delay: -0.3s;
}

.loading div:nth-child(3) {
  animation-delay: -0.15s;
}

.loading div:nth-child(4) {
  opacity: 0.5;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
