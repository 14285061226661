/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Sentry from "@sentry/vue";
import { AxiosRequestConfig, Method } from "axios";

import axios from "../axios";
import { useAuthStore } from "../store/auth";
import {
  APIError,
  AppError,
  FormError,
  convertDateStringToISOInObject,
  convertISOToDateInObject,
  convertISOToDateStringInObject,
  toast,
} from "../utils";

interface ApiCallRequest {
  method: Method;
  path: string;
  data?: any;
  config?: AxiosRequestConfig;
  token?: string;
}

function handleRequestError(requestError: any): void {
  try {
    const { response } = requestError;

    if (!response) {
      throw new AppError("Verifique sua conexão com a internet e tente novamente.");
    }

    if (response.status === 401) {
      useAuthStore().cleanSession();

      const message = "Sua sessão expirou. Faça o login novamente.";

      toast("error", message);
      throw new APIError({ message }, response.status);
    } else {
      if (response.data.properties) {
        throw new FormError(response.data.message, response.data.properties);
      }
      throw new APIError(response.data, response.status);
    }
  } catch (handledError) {
    Sentry.captureException(handledError);

    throw handledError;
  }
}

async function apiCall({ method, path, data, config, token }: ApiCallRequest): Promise<any> {
  const accessToken = token ?? useAuthStore().accessToken;

  const requestConfig: AxiosRequestConfig = {
    method,
    url: path,
    data,
    headers: accessToken ? { Authorization: `Bearer ${accessToken}` } : {},
    ...config,
  };

  try {
    convertDateStringToISOInObject(requestConfig.data, ["birthdate"]);

    const response = await axios(requestConfig);

    if (response.data) {
      convertISOToDateStringInObject(response.data, ["birthdate"]);
      convertISOToDateInObject(response.data, ["createdDate", "updatedDate"]);

      return response.data;
    }

    return undefined;
  } catch (error) {
    handleRequestError(error);
  }
}

export async function apiGet(payload: Omit<ApiCallRequest, "method" | "data">): Promise<any> {
  return apiCall({ method: "GET", ...payload });
}

export async function apiPost(payload: Omit<ApiCallRequest, "method">): Promise<any> {
  return apiCall({ method: "POST", ...payload });
}

export async function apiPatch(payload: Omit<ApiCallRequest, "method">): Promise<any> {
  return apiCall({ method: "PATCH", ...payload });
}

export async function apiDelete(payload: Omit<ApiCallRequest, "method">): Promise<any> {
  return apiCall({ method: "DELETE", ...payload });
}
