import { isArray } from "lodash";

export type NonOptionalKeyOf<T extends object> = Exclude<
  {
    [K in keyof T]: T extends Record<K, T[K]> ? K : never;
  }[keyof T],
  undefined
>;

export type AddSuffix<TKey, TSuffix extends string> = TKey extends string
  ? `${TKey}${TSuffix}`
  : never;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isObject(value: any) {
  // se usar somente o typeof === object datas são incluidas
  return typeof value === "object" && !(value instanceof Date);
}

export function returnFlatObject(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  object: Record<string, any>,
  parentKey?: string
) {
  if (!object) {
    return;
  }

  const newObject = {};

  Object.entries(object).forEach(([key, value]) => {
    if (value !== null && (isArray(value) || isObject(value))) {
      if (isArray(value)) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const array: any = [];

        value.forEach((element) => {
          if (isObject(element)) {
            array.push(Object.assign(newObject, returnFlatObject(element, key)));
          } else {
            array.push(element);
          }
        });

        Object.assign(newObject, { [key]: array });
      } else {
        Object.assign(newObject, returnFlatObject(value, key));
      }
    } else {
      Object.assign(newObject, { [parentKey ? `${parentKey}.${key}` : key]: value });
    }
  });

  return newObject;
}

export function makeOptionsFromEnum(
  enumObject: Record<string, string>,
  translation: Record<string, string>
) {
  return (Object.keys(enumObject) as (keyof typeof enumObject)[]).map((key) => ({
    label: translation[enumObject[key]],
    value: enumObject[key],
  }));
}

export function pickPropertiesFromObject(object: Record<string, string>, properties: string[]) {
  return Object.fromEntries(
    Object.entries(object).filter((entry) => properties.includes(entry[0]))
  );
}

export function removePropertiesFromObject(object: Record<string, string>, properties: string[]) {
  return Object.fromEntries(
    Object.entries(object).filter((entry) => !properties.includes(entry[0]))
  );
}
