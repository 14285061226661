import { APIError } from ".";

export function getIcon(icon: string) {
  return new URL(`../assets/icons/${icon}`, import.meta.url).href;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isAPIError(error: any): error is APIError {
  return error instanceof APIError;
}

export function joinPaths(...paths: string[]) {
  return paths
    .map((path, index) =>
      index === 0 ? path.replace(/\/$/g, "") : path.replace(/\/$/g, "").replace(/^\//g, "")
    )
    .join("/");
}
