import maska from "maska";
import "material-icons/iconfont/material-icons.css";
import { createPinia } from "pinia";
import "v-calendar/dist/style.css";
import { createApp } from "vue";
import toast from "vue-toastification";
import "vue-toastification/dist/index.css";

import App from "./App.vue";
import worker from "./mocks";
import { initializeSentry } from "./plugins/sentry";
import router from "./router";
import "./styles/global.postcss";
import { handleAppError } from "./utils";

if (import.meta.env.VITE_ENVIRONMENT?.toString().toLowerCase() === "dev") {
  worker.start({ onUnhandledRequest: "bypass" });
}

const app = createApp(App) //
  .use(router)
  .use(createPinia())
  .use(maska)
  .use(toast);

app.config.errorHandler = handleAppError;

initializeSentry(app, router);

app.mount("#app");
