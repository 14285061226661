/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Sentry from "@sentry/vue";

import { toast } from "./toast";

/**
 * Erro que pode ser lançado para interromper o fluxo de execução e exibir um toast de erro ao usuário.
 *
 * @class
 * @extends Error
 */
export class AppError extends Error {
  constructor(message: string, public readonly info?: any) {
    super(message);
  }
}

export function handleAppError(error: unknown): void {
  Sentry.captureException(error);

  if (error instanceof AppError || error instanceof APIError) {
    toast("error", error.message);
  } else {
    throw error;
  }
}

export class APIError extends Error {
  constructor(public response: { message: string }, public statusCode: number) {
    super(response.message);
  }
}

export class FormError extends Error {
  constructor(message: string, public properties: PropertyError[]) {
    super(message);
  }
}

interface PropertyError {
  property: string;
  errorMessages: string[];
}
