import { RouteRecordRaw } from "vue-router";

export enum UserPathsEnum {
  HOME = "/home",
  SIGN_UP = "/cadastro",
  PROFILE = "/perfil",
  CATEGORIES = "/categorias",
  RESOURCES = "/recursos",
  PRODUCTS = "/produtos",
  //children paths
  NOTEBOOKS = "notebooks",
  ALL_IN_ONE = "all-in-one",
  MONITORS = "monitores",
  PROJECTORS = "projetores",
  ACCESSORIES = "acessorios",
  DESKTOPS = "desktops",
  SPECIALS = "especiais",
  ACER = "acer",
  PARTNERS = "parceiros",
  MY_ACCOUNT = "minha-conta",
}

export const RouteNamesTranslation = {
  [UserPathsEnum.HOME]: "Home",
  [UserPathsEnum.SIGN_UP]: "Cadastro",
  [UserPathsEnum.PROFILE]: "Minha conta",
  [UserPathsEnum.CATEGORIES]: "Categorias",
  [UserPathsEnum.RESOURCES]: "Recursos",
  [UserPathsEnum.PRODUCTS]: "Página Inicial",
  [UserPathsEnum.NOTEBOOKS]: "Notebooks",
  [UserPathsEnum.ALL_IN_ONE]: "All-In-One",
  [UserPathsEnum.MONITORS]: "Monitores",
  [UserPathsEnum.PROJECTORS]: "Projetores",
  [UserPathsEnum.ACCESSORIES]: "Acessórios",
  [UserPathsEnum.DESKTOPS]: "Desktop",
  [UserPathsEnum.SPECIALS]: "Especiais",
  [UserPathsEnum.ACER]: "Acer",
  [UserPathsEnum.PARTNERS]: "Parceiros",
  [UserPathsEnum.MY_ACCOUNT]: "Minha conta",
};

const routes: RouteRecordRaw[] = [
  {
    path: "/",
    redirect: UserPathsEnum.HOME,
  },
  {
    path: UserPathsEnum.HOME,
    name: RouteNamesTranslation[UserPathsEnum.HOME],
    component: () => import("../views/user/Home.vue"),
  },
  {
    path: UserPathsEnum.SIGN_UP,
    name: RouteNamesTranslation[UserPathsEnum.SIGN_UP],
    component: () => import("../views/user/SignUp.vue"),
  },
  {
    path: UserPathsEnum.PROFILE,
    component: () => import("../components/LayoutNavbarVertical.vue"),
    children: [
      {
        path: UserPathsEnum.MY_ACCOUNT,
        name: RouteNamesTranslation[UserPathsEnum.MY_ACCOUNT],
        component: () => import("../views/user/MyAccount.vue"),
        meta: { requiresAuth: true, priority: 1 },
      },
    ],
  },
  {
    path: UserPathsEnum.PRODUCTS,
    component: () => import("../components/LayoutNavbarVertical.vue"),
    children: [
      {
        path: "",
        name: RouteNamesTranslation[UserPathsEnum.PRODUCTS],
        component: () => import("../views/products/Home.vue"),
        meta: { requiresAuth: true, priority: 0 },
      },
      {
        path: ":productId",
        component: () => import("../views/products/ProductDetail.vue"),
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: UserPathsEnum.CATEGORIES,
    name: RouteNamesTranslation[UserPathsEnum.CATEGORIES],
    component: () => import("../components/LayoutNavbarVertical.vue"),
    meta: { requiresAuth: true, priority: 2 },
    children: [
      {
        path: UserPathsEnum.NOTEBOOKS,
        name: RouteNamesTranslation[UserPathsEnum.NOTEBOOKS],
        component: () => import("../views/user/categories/Products.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: UserPathsEnum.ALL_IN_ONE,
        name: RouteNamesTranslation[UserPathsEnum.ALL_IN_ONE],
        component: () => import("../views/user/categories/Products.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: UserPathsEnum.MONITORS,
        name: RouteNamesTranslation[UserPathsEnum.MONITORS],
        component: () => import("../views/user/categories/Products.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: UserPathsEnum.PROJECTORS,
        name: RouteNamesTranslation[UserPathsEnum.PROJECTORS],
        component: () => import("../views/user/categories/Products.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: UserPathsEnum.ACCESSORIES,
        name: RouteNamesTranslation[UserPathsEnum.ACCESSORIES],
        component: () => import("../views/user/categories/Products.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: UserPathsEnum.DESKTOPS,
        name: RouteNamesTranslation[UserPathsEnum.DESKTOPS],
        component: () => import("../views/user/categories/Products.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: UserPathsEnum.SPECIALS,
        name: RouteNamesTranslation[UserPathsEnum.SPECIALS],
        component: () => import("../views/user/categories/Products.vue"),
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: UserPathsEnum.RESOURCES,
    name: RouteNamesTranslation[UserPathsEnum.RESOURCES],
    component: () => import("../components/LayoutNavbarVertical.vue"),
    children: [
      {
        path: UserPathsEnum.ACER,
        name: RouteNamesTranslation[UserPathsEnum.ACER],
        component: () => import("../views/user/resources/Resources.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: UserPathsEnum.PARTNERS,
        name: RouteNamesTranslation[UserPathsEnum.PARTNERS],
        component: () => import("../views/user/resources/Resources.vue"),
        meta: { requiresAuth: true },
      },
    ],
  },
];

export default routes;
